import { useApiConfig } from "src/api/ApiConfigProvider";
import { useQueryClient } from "@tanstack/react-query";
import { getTripCacheKey, TripPlanWithID, updateTripPlan } from "../util/api";
import { getTripQueryKey } from "./useTripPlanQueries";

export function usePutTripPlan() {
  const apiConfig = useApiConfig();
  const queryClient = useQueryClient();

  async function putTripPlanQuery(
    tripPlannerDetails: TripPlanWithID,
    hasAuth: boolean = false
  ) {
    const cacheKey = getTripCacheKey(tripPlannerDetails, hasAuth);
    queryClient.invalidateQueries({ queryKey: cacheKey });
    const result = await updateTripPlan(apiConfig, tripPlannerDetails, hasAuth);
    queryClient.setQueryData(getTripQueryKey(tripPlannerDetails.id), result);
    return result;
  }

  return putTripPlanQuery;
}

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useQueryClient } from "@tanstack/react-query";
import { GeocodedPlace } from "src/PrefetchData";
import { AutocompletePlace } from "src/api/AutocompleteResponse";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { getCacheKeyForGeocode, useGeocode } from "src/utils/hooks/useGeocode";

type EditPlaceReturn = {
  setEditedPlace: Dispatch<SetStateAction<AutocompletePlace | undefined>>;
};
export function useEditPlace(
  index: number,
  onGeocodedCallback: (newPlace: GeocodedPlace) => void
): EditPlaceReturn {
  const { dispatch, dispatchExpandedPlaces } = useTripPlannerContext();
  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);
  const [editingPlace, setEditedPlace] = useState<
    AutocompletePlace | undefined
  >();

  const queryClient = useQueryClient();

  const {
    data: newPlace,
    isLoading,
    isRefetching,
  } = useGeocode(editingPlace?.canonicalName);

  useEffect(() => {
    if (newPlace && !newPlace.lat) {
      // We need to invalidate the cache so we force a fetch to get get the new lat/lng
      // This is because the autocomplete hook has cached place data that doesn't have lat/lng
      queryClient.invalidateQueries({
        queryKey: getCacheKeyForGeocode(newPlace.canonicalName, languageCode),
      });
    }
    if (!isLoading && !isRefetching && newPlace && newPlace.lat) {
      dispatch({
        type: "EDIT_DESTINATION",
        index,
        newPlace: newPlace as GeocodedPlace,
      });
      if (index < 2) {
        dispatchExpandedPlaces({
          type: "EXPAND_BY_POSITION",
          index: 0,
        });
      }
      onGeocodedCallback(newPlace as GeocodedPlace);
      setEditedPlace(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingPlace, isLoading, isRefetching]);

  return { setEditedPlace };
}

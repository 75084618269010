import { useCallback, useRef } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { getPath } from "src/utils/url";
import { useFeature } from "src/feature/useFeature";
import useUser from "src/utils/hooks/useUser";
import { encodeSearchParamsForUrl } from "src/utils/encodeSearchParamsForUrl";
import { useLayout } from "src/utils/hooks/useLayout";
import { TripPlannerDetails } from "../TripPlannerProvider";
import { MAX_SEARCH_PARAM_PLACES } from "./useSwitchURLToTripId";
import { setSearchParamFromPlaces } from "./useTripSearchParam";

export default function useUpdatePathForTrip() {
  const intl = useIntl();
  const location = useTypedLocation();
  const navigate = useNavigate();
  const isTripURLFeature = useFeature("TripURL");
  const { user } = useUser();
  const updatedPathRef = useRef<string | undefined>(location.pathname);
  const layout = useLayout();

  const updateTripPath = useCallback(
    (tripPlannerDetails: TripPlannerDetails) => {
      if (tripPlannerDetails.places.length < 2) {
        return;
      }
      const [origin, destination] = tripPlannerDetails.places;

      if (layout !== "mobile") {
        updatedPathRef.current = getPath(
          origin.canonicalName,
          destination.canonicalName,
          localeToLanguageCode(intl.locale)
        );
      }

      if (updatedPathRef.current === location.pathname && !isTripURLFeature) {
        return;
      }
      let search: string | undefined = location.search;
      const shouldUpdateTripSearchParam =
        isTripURLFeature &&
        !user?.id &&
        !tripPlannerDetails.slug &&
        tripPlannerDetails.places.length < MAX_SEARCH_PARAM_PLACES;

      if (shouldUpdateTripSearchParam) {
        const places = tripPlannerDetails.places;
        const searchParams = new URLSearchParams(location.search);
        setSearchParamFromPlaces(searchParams, places);
        search = encodeSearchParamsForUrl(searchParams);
      }
      navigate({
        ...location,
        pathname: updatedPathRef.current,
        search,
      });
    },
    [isTripURLFeature, user?.id, intl.locale, location, navigate, layout]
  );

  return { updateTripPath, updatedPathRef };
}

import {
  DragUpdate,
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import { ErrorBoundary } from "@sentry/react";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { GeocodedPlace } from "src/PrefetchData";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { getDragChanges } from "src/components/DragAndDropList/useDragAndDropList";
import { Icon } from "src/components/Icon/Icon";
import { TripPlannerPlaceTitle } from "src/components/TripPlanner/Headings/PlaceTitle";
import { HotelsCarousel } from "src/components/TripPlanner/Hotels/HotelsCarousel";
import { HotelsCarouselHeading } from "src/components/TripPlanner/Hotels/components/HotelsCarouselHeading";
import { MoreOptionsButton } from "src/components/TripPlanner/MoreOptionsButton/MoreOptionsButton";
import { TripCardHeading } from "src/components/TripPlanner/TripCard/TripCardHeading/TripCardHeading";
import {
  TripCardInputHeading,
  LoadingHeading,
} from "src/components/TripPlanner/TripCard/TripCardHeading/TripCardInputHeading";
import { useFeature } from "src/feature/useFeature";
import { ChevronRightBold } from "src/svg/ChevronRightBold";
import styled, { css } from "styled-components";
import {
  useNavigateToHotelsPage,
  useNavigateToTripsHotels,
} from "src/utils/hooks/useNavigateToHotelsPage";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";

import { TripCardInputHeadingSimple } from "src/components/TripPlanner/TripCard/TripCardHeading/TripCardInputHeadingSimple";
import { useLayout } from "src/utils/hooks/useLayout";
import SimpleRouteCardLoading from "src/components/TripPlanner/TripCard/SimpleRouteCard/SimpleRouteCardLoading";
import { useKayakExitOnHExEntry } from "src/domain/HotelsScreen/utils-exit-to-provider";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  spacing,
} from "../../../theme";
import { LocationNights } from "../Dates/LocationNights";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { createTransportKey } from "../util/createTransportKey";
import messages from "./TripPlannerCard.messages";
import { TripPlannerTravelBlock } from "./TripPlannerTravelBlock";

export type TripPlannerCardCallbacks = {
  hoverCallback?: VoidFunction;
};

type TripPlannerCardProps = {
  places: GeocodedPlace[];
  index: number;
  callbacks: TripPlannerCardCallbacks;
  scrollRef?: React.RefObject<HTMLDivElement>;
  isUpdated?: Boolean;
  startDate?: string;
};

export function TripPlannerCard({
  places,
  index,
  callbacks,
  scrollRef,
  isUpdated,
}: TripPlannerCardProps) {
  const { activeTripPlannerTab } = useTripPlannerContext();
  const place = places[index];
  const isLastPlace = index === places.length - 1;
  const nextPlace = !isLastPlace ? places[index + 1] : undefined;
  const canonicalPair = createTransportKey(
    place.canonicalName,
    nextPlace ? nextPlace.canonicalName : ""
  );

  return (
    <CardWrapper
      ref={scrollRef}
      onMouseEnter={callbacks.hoverCallback}
      data-testid={`trip-planner-card-${index}`}
      $isUpdated={!!isUpdated}
    >
      {activeTripPlannerTab === "hotels" ? (
        <>
          <HotelsCarouselHeading
            geocodedPlace={place}
            places={places}
            index={index}
            canonicalPair={canonicalPair}
          />
          <Row>
            <ErrorBoundary fallback={<ErrorMessage />}>
              <HotelsCarousel index={index} origin={place} />
            </ErrorBoundary>
          </Row>
        </>
      ) : (
        <>
          <HeaderRow>
            <TripPlannerPlaceTitle index={index}>
              {place.longName ?? place.shortName}
            </TripPlannerPlaceTitle>
            <MoreOptionsButton
              canonicalPair={canonicalPair}
              place={place}
              index={index}
            />
          </HeaderRow>
          {nextPlace !== undefined && (
            <Row>
              <ErrorBoundary fallback={<ErrorMessage />}>
                <TripPlannerTravelBlock
                  index={index}
                  origin={place}
                  destination={nextPlace}
                />
              </ErrorBoundary>
            </Row>
          )}
        </>
      )}
    </CardWrapper>
  );
}

type DraggableTripPlannerCardProps = TripPlannerCardProps & {
  place: GeocodedPlace;
  toggle?: (index: number) => void;
  isExpanded?: boolean;
  isDragging?: boolean;
  dragHandleProps?: Partial<DraggableProvidedDragHandleProps> & {
    "data-rbd-drag-handle-draggable-id": string;
  };
  dragSnapshot?: DraggableStateSnapshot;
  dropSnapshot?: DroppableStateSnapshot;
  dragStatus?: DragUpdate;
  onRemove: () => void;
};

export function DraggableTripPlannerCard(props: DraggableTripPlannerCardProps) {
  const { index, places, place, startDate } = props;
  const { tripDestination, isMultiTrip } = useTripPlannerContext();
  const getDragEffects = useCallback(
    () => getDragChanges(index, props.dragStatus, props.dropSnapshot),
    [index, props.dragStatus, props.dropSnapshot]
  );
  const isLastPlace = index === places.length - 1;
  const nextPlace = !isLastPlace ? places[index + 1] : undefined;
  const canonicalPair = createTransportKey(
    place.canonicalName,
    nextPlace ? nextPlace.canonicalName : ""
  );

  const { isDraggingThis, isEffectedByDrag, isDragEnding } = getDragEffects();
  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();
  const showDatesInTripPlanner = useFeature("DatesInTripPlanner") && startDate;
  const isSimpleSearchV2 = useFeature("SimpleSearchV2");

  const hideHeading = isSimpleSearchV2 && !isMultiTrip;
  const showTripCardInputHeading = isTripsAsCoreHoldback && isMultiTrip;
  const showTripCardInputHeadingSimple = isTripsAsCoreHoldback && !isMultiTrip;

  return (
    <CardWrapper
      ref={props.scrollRef}
      onMouseOver={props.callbacks.hoverCallback}
      data-testid={`trip-planner-card-${index}`}
      $isDragging={props.isDragging}
      $isUpdated={!!props.isUpdated}
      $isTripsAsCoreHoldback={isTripsAsCoreHoldback}
      $isSimple={showTripCardInputHeadingSimple}
    >
      {showTripCardInputHeading ? (
        <TripCardInputHeading
          isDragEnding={isDragEnding}
          draggableProperties={props.dragHandleProps}
          geocodedPlace={place}
          index={index}
          canonicalPair={canonicalPair}
          onRemove={props.onRemove}
        />
      ) : showTripCardInputHeadingSimple ? (
        <TripCardInputHeadingSimple
          index={index}
          geocodedPlace={place}
          draggableProperties={props.dragHandleProps}
          isHidden={hideHeading}
        />
      ) : (
        <TripCardHeading
          isDragEnding={isDragEnding}
          draggableProperties={props.dragHandleProps}
          geocodedPlace={place}
          index={index}
          canonicalPair={canonicalPair}
        />
      )}
      {isTripsAsCoreHoldback && places.length !== 2 && (
        <AddHotelCTA
          {...{ index, places, place }}
          $isTripsAsCoreHoldback={isTripsAsCoreHoldback}
        />
      )}
      {showDatesInTripPlanner && <LocationNights />}
      {nextPlace === undefined && tripDestination.destinationCanonical && (
        <SimpleRouteCardLoading />
      )}
      {nextPlace !== undefined && (
        <Row>
          <ErrorBoundary fallback={<ErrorMessage />}>
            <TripPlannerTravelBlock
              toggle={() => {
                if (props.toggle) {
                  props.toggle(index);
                }
              }}
              isExpanded={props.isExpanded}
              isDragging={props.isDragging}
              isDraggable={true}
              isPendingChanges={isDraggingThis || isEffectedByDrag}
              index={index}
              origin={place}
              destination={nextPlace}
            />
          </ErrorBoundary>
        </Row>
      )}
    </CardWrapper>
  );
}

type LoadingCardProps = {
  index: number;
  name?: string;
};

export function LoadingCard(props: LoadingCardProps) {
  return (
    <CardWrapper>
      <LoadingHeading {...props} />
    </CardWrapper>
  );
}

function ErrorMessage() {
  const intl = useIntl();
  return (
    <ErrorContainer>
      <p>{intl.formatMessage(messages.transportError)}</p>
    </ErrorContainer>
  );
}

type AddHotelCTAProps = {
  index: number;
  place: GeocodedPlace;
  places: GeocodedPlace[];
  $isTripsAsCoreHoldback?: boolean;
};

function AddHotelCTA(props: AddHotelCTAProps) {
  const hexIsKayak = useFeature("HExIsKayak");
  const { index, places, place } = props;
  const layout = useLayout();
  const isStackedNavigationAccom =
    useFeature("StackedNavigationAccom") && layout === "desktop";
  const handleKayakExit = useKayakExitOnHExEntry();

  const originCanonical =
    index === 0 ? place?.canonicalName : places[index - 1]?.canonicalName;

  const intl = useIntl();
  const navigateToTripsHotels = useNavigateToTripsHotels();
  const { navigateToHotels } = useNavigateToHotelsPage();

  function handleAccomClick() {
    sendAnalyticsInteractionEvent(
      "SearchResults",
      "Click:HotelPromoMultipleDest",
      place.canonicalName
    );

    if (hexIsKayak) {
      handleKayakExit({
        lat: place?.lat,
        lng: place?.lng,
        canonicalName: place?.canonicalName,
      });
    } else {
      if (isStackedNavigationAccom) {
        const destinationCanonical =
          index === 0 ? undefined : place.canonicalName;
        navigateToTripsHotels(originCanonical, destinationCanonical);
      } else {
        const destinationCanonical = place.canonicalName;
        navigateToHotels({
          originCanonical,
          destinationCanonical,
        });
      }
    }
  }

  return (
    <AddHotelsCta
      onClick={() => {
        handleAccomClick();
      }}
      $isTripsAsCoreHoldback={props.$isTripsAsCoreHoldback}
    >
      {intl.formatMessage(messages.viewHotels)}
      <Icon size="sm-1" data-testid="chevron">
        <ChevronRightBold tint="pink" />
      </Icon>
    </AddHotelsCta>
  );
}

const HeaderRow = styled.div`
  padding: ${spacing.xs} 0 0;
  margin-bottom: ${spacing.lg};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Row = styled.div`
  padding: ${spacing.xs} 0;
`;

const CardWrapper = styled.div<{
  $isUpdated?: boolean;
  $isDragging?: boolean;
  $isTripsAsCoreHoldback?: boolean;
  $isSimple?: boolean;
}>`
  padding: ${spacing.lg} 0 ${spacing.xs};
  transition: transform 0.3s ease;
  ${({ $isDragging }) => $isDragging && `pointer-events: none; `}

  ${({ $isTripsAsCoreHoldback }) =>
    $isTripsAsCoreHoldback && `margin-left: -14px;`}

  ${({ $isSimple }) =>
    $isSimple &&
    css`
      margin-left: -58px;
      padding-top: 0;
    `}
`;
const ErrorContainer = styled.div`
  padding: ${spacing.lg};
  border-radius: ${borderRadius.md};
  background-color: ${color.white};
`;

// Hotels in Trip Planner Experiment 1

const TypographyBody = styled.div`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.body};
  color: ${color.cod};
`;

const AddHotelsCta = styled(TypographyBody)<{
  $isTripsAsCoreHoldback?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;

  gap: ${spacing.md};
  margin: -${spacing.md} 0 0 ${spacing.md};
  margin-right: ${(props) =>
    props.$isTripsAsCoreHoldback ? spacing.xxl : spacing.md};
  padding-left: ${(props) =>
    props.$isTripsAsCoreHoldback ? `${spacing.md}` : "40px"};
  padding-bottom: ${spacing.sm};

  font-size: ${(props) =>
    props.$isTripsAsCoreHoldback ? `${fontSize.md}` : "inherit"};
  font-weight: ${fontWeight.medium};
  color: ${color.pink};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;

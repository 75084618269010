import { useQuery } from "@tanstack/react-query";
import { Mode } from "src/utils/types/mode";
import { PassengerDetails } from "src/PassengerDetailsProvider";
import { SchedulesResponse } from "src/api/SchedulesResponse";
import { SearchResponse } from "src/api/SearchResponse";
import { useFeature } from "src/feature/useFeature";
import useUser from "src/utils/hooks/useUser";
import { useTravelSearchSettings } from "src/domain/SegmentScreen/TravelSearchSettingsProvider";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";

import useSearch from "src/utils/hooks/useSearch";
import { AdaraEvent, sendAdaraEvent, TransportSearch } from "./sendAdaraEvent";
import { getAdaraTransportData } from "./getAdaraTransportData";

export function useAdara(
  searchResponse?: SearchResponse,
  schedulesResponse?: SchedulesResponse,
  passengers?: PassengerDetails,
  transitMode?: Mode,
  routeIndex?: number
) {
  const isAdaraEnabled = useFeature("AdaraIntegration");
  const { language } = useUser();
  const { returnDate } = useTravelSearchSettings().dateTimeObject;
  const numTripLegs = useTripPlannerContext().tripRoutes.placePairs.length;
  const { origin, destination } = useSearch();

  useQuery({
    queryKey: [
      "useAdara",
      isAdaraEnabled,
      searchResponse?.request,
      schedulesResponse,
    ],
    queryFn: () => {
      if (
        !isAdaraEnabled ||
        !origin ||
        !destination ||
        (transitMode && schedulesResponse === undefined)
      ) {
        return "";
      }

      const data = getAdaraTransportData<TransportSearch>({
        searchResponse,
        routeIndex,
        schedulesResponse,
        transitMode,
        numTripLegs,
        returnDate,
      });

      const adaraEvent: AdaraEvent = {
        event: "transportSearch",
        travelProductType: transitMode,
        departureDate: data.departureDate,
        departureCity: origin.shortName,
        departureState: "regionCode" in origin ? origin.regionCode : undefined,
        departureCountry: data.departureCountry,
        departureAirportCode: data.departureAirportCode,
        arrivalDate: data.arrivalDate,
        arrivalCity: destination.shortName,
        arrivalState:
          "regionCode" in destination ? destination.regionCode : undefined,
        arrivalCountry: data.arrivalCountry,
        arrivalAirportCode: data.arrivalAirportCode,
        price: data.price,
        currencyType: data.currencyType,
        ticketClass: data.ticketClass,
        numPassengers: passengers?.length,
        tripType: data.tripType,
        siteLanguage: language,
      };

      sendAdaraEvent(adaraEvent);
      // Queries must return a value.
      return "";
    },
    refetchOnWindowFocus: false,
  });
}

import { useReducer } from "react";
import { useIntl } from "react-intl";
import { UseQueryOptions, useQueries } from "@tanstack/react-query";
import { SearchResponse } from "src/api/SearchResponse";
import { PlacePair } from "src/domain/TripPlanner/util/getPlacePairs";
import { ApiConfig } from "../../api/ApiConfig";
import { useApiConfig } from "../../api/ApiConfigProvider";
import { localeToLanguageCode } from "../conversions/languageCode";
import { ONE_DAY_IN_MILLISECONDS } from "../conversions/time";
import { SupportedCurrencyCode } from "../currency";
import { SupportedLanguageCode } from "../language";
import { getCacheKeyForSearch, search } from "../search";
import { useSearchParams } from "./useSearchParams";
import { useTypedLocation } from "./useTypedLocation";
import useUser from "./useUser";
import { getTransportKeyFromHash } from "./useTripTransportIndex";
import { useIsTripScreen } from "./useIsTripScreen";

export function useGetTripRoutes(places: PlacePair[]) {
  const { currencyCode } = useUser();
  const intl = useIntl();
  const config = useApiConfig();
  const searchParams = useSearchParams();
  const searchDate = searchParams.get("oDateTime") ?? "anytime";
  const languageCode = localeToLanguageCode(intl.locale);
  const location = useTypedLocation();
  const activeKey = getTransportKeyFromHash(location.hash);
  const isTripScreen = useIsTripScreen();

  const [activeQueries, setActiveQueries] = useReducer(
    setActiveQueryReducer,
    places.map((placePair) => {
      if (
        `${placePair.origin.canonicalName}_${placePair.destination.canonicalName}` ===
        activeKey
      ) {
        return true;
      }
      return false;
    })
  );

  const tripPlanQueries = getQueriesForTripPlan(
    activeQueries,
    places,
    currencyCode,
    languageCode,
    searchDate,
    config,
    isTripScreen
  );

  const allSearchResults = useQueries({ queries: tripPlanQueries });

  return {
    setActiveQueries,
    activeQueries,
    queries: allSearchResults,
    placePairs: places,
    isLoading: allSearchResults.some((result) => result.isLoading),
  };
}

function setActiveQueryReducer(
  activeQueries: boolean[],
  action: { index: number; value: boolean }
) {
  const newActiveQueries = [...activeQueries];
  newActiveQueries[action.index] = action.value;
  return newActiveQueries;
}

function getQueriesForTripPlan(
  activeQueries: boolean[],
  tripPairs: PlacePair[],
  currencyCode: SupportedCurrencyCode,
  languageCode: SupportedLanguageCode,
  searchDate: string,
  config: ApiConfig,
  isTripScreen: boolean
): UseQueryOptions<SearchResponse>[] {
  let queries: UseQueryOptions<SearchResponse>[] = [];
  tripPairs.forEach((place, index) => {
    const searchKey = getCacheKeyForSearch(
      place.origin.canonicalName,
      place.destination.canonicalName,
      currencyCode,
      languageCode,
      searchDate
    );
    queries.push({
      queryKey: searchKey,
      queryFn: async () =>
        await search(
          place.origin,
          place.destination,
          currencyCode,
          languageCode,
          config
        ),
      gcTime: ONE_DAY_IN_MILLISECONDS,
      staleTime: ONE_DAY_IN_MILLISECONDS,
      retryOnMount: false,
      retry: 3,
      refetchOnMount: false,
      enabled: isTripScreen && activeQueries[index],
      notifyOnChangeProps: ["data"],
      throwOnError: true,
    });
  });

  return queries;
}

import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { ApiConfig } from "src/api/ApiConfig";
import { useApiConfig } from "src/api/ApiConfigProvider";
import { ONE_DAY_IN_MILLISECONDS } from "src/utils/conversions/time";
import {
  TRIPS_API_ERROR,
  TripPlanWithID,
  getTripPlan,
  onSingleCompleteCallback,
} from "../util/api";

type TripQueryOptions = UseQueryOptions<
  TripPlanWithID | undefined,
  {
    message: (typeof TRIPS_API_ERROR)[number];
  }
>;

function getDirectQueryOptions(
  apiConfig: ApiConfig,
  onCompleteCallback: onSingleCompleteCallback,
  enabled: boolean,
  id?: string
): TripQueryOptions {
  return {
    enabled,
    queryKey: ["directTripPlan", id],
    retry: 3,
    gcTime: ONE_DAY_IN_MILLISECONDS,
    staleTime: ONE_DAY_IN_MILLISECONDS,
    refetchOnWindowFocus: false,
    queryFn: async () => await getTripPlan(onCompleteCallback, apiConfig, id),
  };
}

export function useTripPlanQuery(
  onCompleteCallback: onSingleCompleteCallback,
  enabled: boolean,
  id?: string
) {
  const apiConfig = useApiConfig();

  return useQuery(
    getDirectQueryOptions(apiConfig, onCompleteCallback, enabled, id)
  );
}

export function getTripQueryKey(id?: string) {
  return ["directTripPlan", id];
}

import styled from "styled-components";
import { useIntl } from "react-intl";
import { ChangeTransferIcon } from "src/svg/ChangeTransfer";
import { primitive } from "src/design-system/tokens/color";
import { color, fontSize, spacing } from "../../../../theme";
import { Timeline } from "../../Timeline";
import { TransferCell } from "../cell/TransferCell";
import { transitSwitchMessages } from "./TroniconTimelineTransfer.messages";

type Props = {
  cell: TransferCell;
  className?: string;
};

export function TroniconTimelineTransfer({ className, cell }: Props) {
  const intl = useIntl();

  return (
    <Wrapper className={className}>
      <Timeline type="transfer" transitMode="walk" />
      <LeftAligned>
        <ChangeTransferIcon tint={primitive.neutral_1100} />
        {intl.formatMessage(transitSwitchMessages[cell.label])}
      </LeftAligned>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 12px;
  padding-left: 16px;
`;

const LeftAligned = styled.span`
  height: 100%;
  margin-right: auto;
  padding-left: ${spacing.xl};
  line-height: 24px;
  font-size: ${fontSize.h6};
  color: ${color.n90};

  display: flex;
  align-items: center;
  gap: ${spacing.xl};
  padding-top: ${spacing.lg};
  padding-bottom: ${spacing.lg};
`;

import styled, { ThemeProvider } from "styled-components";
import { isSaveableSearchResponse } from "src/domain/TripPlanner/util/isSaveableSearchResponse";
import useSearch from "src/utils/hooks/useSearch";
import { primitive } from "src/design-system/tokens/color";
import { FormattedDuration } from "../../../components/FormattedDuration/FormattedDuration";
import {
  FormattedPriceRange,
  FormattedPriceRangeProps,
} from "../../../components/FormattedPriceRange/FormattedPriceRange";
import { fontSize, fontWeight, screenMinWidth, spacing } from "../../../theme";
import { desktopLayout, useLayout } from "../../../utils/hooks/useLayout";
import { useTheme } from "../../../utils/hooks/useTheme";
import { PaneNav, PaneNavProps } from "../../PaneNav/PaneNav";

type RouteHeaderProps = {
  title: string;
  durationInMinutes: number;
  priceRange?: FormattedPriceRangeProps;
  className?: string;
  routeCanonical: string;
  onBackClick: () => void;
  onTripSaveClick?: () => void;
  hidePaneNav?: boolean;
};

export function RouteHeader(props: RouteHeaderProps) {
  const layout = useLayout();
  const showPaneNav = layout !== "mobile" && !props.hidePaneNav;
  const { searchResponse } = useSearch();

  // The RouteHeader has a light background when using the light theme, we need
  // to overwrite the PaneNav breadcrumbs to be the same as the route header.
  const theme = useTheme();
  theme.breadcrumbs = theme.routeHeader;

  return (
    <Header className={props.className}>
      {showPaneNav && (
        <ThemeProvider theme={theme.routeHeader}>
          <StyledPaneNav
            routeCanonical={props.routeCanonical}
            showRouteBreadcrumb={true}
            onBackClick={props.onBackClick}
            isSaveableScreen={isSaveableSearchResponse(searchResponse)}
            onTripSaveClick={props.onTripSaveClick}
            isGreyHeader
          />
        </ThemeProvider>
      )}
      <Title>{props.title}</Title>
      <Information>
        <FormattedDuration totalMinutes={props.durationInMinutes} />
        {props.priceRange ? <InfoDot /> : null}
        {props.priceRange && <FormattedPriceRange {...props.priceRange} />}
      </Information>
    </Header>
  );
}

const Header = styled.div`
  padding: ${spacing.xl};
  ${screenMinWidth.md} {
    padding-top: 0;
  }
  min-height: 79px; /** So we have a rounded number for the loading component */
`;

const Title = styled.h4`
  font-weight: ${fontWeight.medium};
  color: ${primitive.neutral_1500};

  ${desktopLayout} {
    font-size: ${fontSize.h3};
  }
`;

const Information = styled.div`
  display: flex;
  gap: ${spacing.md};
  align-items: center;
  padding-top: ${spacing.md};
  color: #707070;
  font-size: ${fontSize.h5};
`;

const InfoDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #707070;
`;

const StyledPaneNav = styled(PaneNav)<PaneNavProps>`
  padding-bottom: ${spacing.lg};
`;

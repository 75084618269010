import styled from "styled-components";
import { DottedTimeline } from "src/svg/DottedTimeline";
import { useColor } from "../../svg/common";
import { DestinationMarker } from "../../svg/DestinationMarker";
import { HotelAlternative } from "../../svg/HotelAlternative";
import { iconSize, spacing, transitColor } from "../../theme";
import { desktopLayout, useLayout } from "../../utils/hooks/useLayout";
import { Mode } from "../../utils/types/mode";
import { TransitIcon } from "../Icon/TransitIcon";

type Props = {
  type:
    | "origin"
    | "detail"
    | "destination"
    | "interchange"
    | "transfer"
    | "stop"
    | "tripEnd"
    | "accommodation";
  transitMode: Mode;
  className?: string;
  isLongerTimelineLine?: boolean;
  isOriginTimelineHidden?: boolean;
};

export function Timeline(props: Props) {
  return (
    <Container
      className={props.className}
      data-testid="timeline-line"
      $isVerticallyCentered={props.isOriginTimelineHidden}
    >
      <TimelineLine
        type={props.type}
        transitMode={props.transitMode}
        isLongerTimelineLine={props.isLongerTimelineLine}
        isOriginTimelineHidden={props.isOriginTimelineHidden}
      />
    </Container>
  );
}

function TimelineLine(props: Props) {
  const tintColor = useColor(props.transitMode);
  switch (props.type) {
    case "origin":
      return (
        <>
          <StyledIcon
            transitMode={props.transitMode}
            $isBottomMargin={!props.isOriginTimelineHidden}
          >
            <TransitIcon
              transitMode={props.transitMode}
              tint={props.transitMode}
            />
          </StyledIcon>
          {!props.isOriginTimelineHidden ? (
            <OriginTimeline
              transitMode={props.transitMode}
              tintColor={tintColor}
            />
          ) : null}
        </>
      );
    case "detail":
      return (
        <DetailTimeline
          transitMode={props.transitMode}
          isLongerTimelineLine={props.isLongerTimelineLine}
        />
      );
    case "destination":
      return <DestinationTimeline transitMode={props.transitMode} />;
    case "stop":
    case "interchange":
      // The stop timeline is identical to the interchange timeline.
      return <InterchangeTimeline transitMode={props.transitMode} />;
    case "transfer":
      return <CompactDottedTimeline />;
    case "tripEnd":
      return <TripEndTimeline transitMode={props.transitMode} />;
    case "accommodation":
      return <AccommodationTimeline />;
  }
}

function AccommodationTimeline() {
  return <HotelAlternative tint="blue" />;
}

function TripEndTimeline(props: { transitMode: Mode }) {
  return (
    <DestinationMarkerWrapper>
      <DestinationMarker tint={props.transitMode} outline={false} />
    </DestinationMarkerWrapper>
  );
}

const DestinationMarkerWrapper = styled.div`
  svg {
    width: 32px !important;
    height: 24px !important;
    transform: translateX(-4px);
    margin-right: -8px;
  }
  ${desktopLayout} {
    svg {
      width: 32px !important;
      height: 38px !important;
    }
  }
`;

function DestinationTimeline(props: { transitMode: Mode }) {
  const tintColor = useColor(props.transitMode);
  const isFerry = ["ferry", "carferry"].includes(props.transitMode);
  const showLine = !isFerry && props.transitMode !== "walk";
  const strokeWidth = "2";
  const circleRadius = "4";
  return (
    <svg viewBox="0 0 24 24" preserveAspectRatio="none">
      {isFerry && (
        <path
          fill="none"
          stroke={tintColor}
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
          d="M 11.25,-5 12.75,0 11.25,5"
        />
      )}
      {showLine && (
        <line
          x1="12"
          y1="0"
          x2="12"
          y2="8"
          stroke={tintColor}
          strokeWidth={strokeWidth}
        />
      )}
      <circle
        cx="12"
        cy="12"
        r={circleRadius}
        stroke={tintColor}
        strokeWidth={strokeWidth}
        fill="none"
      ></circle>
    </svg>
  );
}

function InterchangeTimeline(props: { transitMode: Mode }) {
  const tintColor = useColor(props.transitMode);
  const isMobile = useLayout() === "mobile";
  const isFerry = ["ferry", "carferry"].includes(props.transitMode);
  const showLine = !isFerry && !["walk", "plane"].includes(props.transitMode);
  const strokeWidth = isMobile ? "2" : "3.5";
  const lineLength = "8";
  const lineStart = "16";

  return (
    <InterchangeSVG viewBox="0 0 24 24" preserveAspectRatio="none">
      {isFerry && (
        <path
          fill="none"
          stroke={tintColor}
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
          d="M 11.25,-5 12.75,0 11.25,5"
        />
      )}
      {showLine && (
        <line
          x1="12"
          y1="0"
          x2="12"
          y2={lineLength}
          stroke={tintColor}
          strokeWidth={strokeWidth}
        />
      )}
      <circle cx="12" cy="12" r="4" fill={tintColor} />
      {isFerry && (
        <path
          fill="none"
          stroke={tintColor}
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
          d="M 11.25,18 11.25,19 12.75,24 11.25,29"
        />
      )}
      {showLine && (
        <line
          x1="12"
          y1={lineStart}
          x2="12"
          y2="300%" // Covers 3 lines of text if needed
          stroke={tintColor}
          strokeWidth={strokeWidth}
        />
      )}
    </InterchangeSVG>
  );
}

function DetailTimeline(props: {
  transitMode: Mode;
  isLongerTimelineLine?: boolean;
}) {
  switch (props.transitMode) {
    case "walk":
      return <WalkDetailTimeline />;
    case "ferry":
    case "carferry":
      return <FerryDetailTimeline />;
    default:
      return <LandOrPLaneDetailTimeline {...props} />;
  }
}

function OriginTimeline(props: { transitMode: Mode; tintColor: string }) {
  const strokeWidth = "2";
  const walkTintColor = useColor("n40");

  if (props.transitMode === "walk") {
    return (
      <TimelineReducerWalk>
        <svg viewBox="0 0 24 12">
          <circle cx="12" cy="2" r="3.5" fill={walkTintColor} />
        </svg>
      </TimelineReducerWalk>
    );
  }
  return (
    <TimelineReducer>
      <svg viewBox="0 0 24 48" preserveAspectRatio="none">
        <line
          x1="12"
          y1="0"
          x2="12"
          y2="100%"
          fill="none"
          stroke={props.tintColor}
          strokeWidth={strokeWidth}
          vectorEffect="non-scaling-stroke"
        ></line>
      </svg>
    </TimelineReducer>
  );
}

function WalkDetailTimeline() {
  const tintColor = useColor("n40");
  return (
    <WalkContainer>
      <svg viewBox="0 0 24 92">
        <circle cx="12" cy="8" r="3.5" fill={tintColor} />
        <circle cx="12" cy="19" r="3.5" fill={tintColor} />
        <circle cx="12" cy="30" r="3.5" fill={tintColor} />
        <circle cx="12" cy="41" r="3.5" fill={tintColor} />
        <circle cx="12" cy="52" r="3.5" fill={tintColor} />
        <circle cx="12" cy="63" r="3.5" fill={tintColor} />
        <circle cx="12" cy="74" r="3.5" fill={tintColor} />
        <circle cx="12" cy="85" r="3.5" fill={tintColor} />
      </svg>
    </WalkContainer>
  );
}

export function CompactDottedTimeline() {
  return (
    <CompactDottedTimelineContainer>
      <DottedTimeline tint="n40" />
    </CompactDottedTimelineContainer>
  );
}

function FerryDetailTimeline() {
  return <FerryContainer />;
}

function LandOrPLaneDetailTimeline(props: {
  transitMode: Mode;
  isLongerTimelineLine?: boolean;
}) {
  const tintColor = useColor(props.transitMode);
  const strokeWidth = "2";

  return (
    <LandOrPLaneContainer
      color={tintColor}
      isLongerTimelineLine={props.isLongerTimelineLine}
    >
      <svg viewBox="0 0 24 48" preserveAspectRatio="none">
        <line
          x1="12"
          y1="0"
          x2="12"
          y2="100%"
          fill="none"
          stroke={tintColor}
          strokeWidth={strokeWidth}
          vectorEffect="non-scaling-stroke"
        ></line>
      </svg>
    </LandOrPLaneContainer>
  );
}

const Container = styled.div<{ $isVerticallyCentered?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin: ${(props) => (props.$isVerticallyCentered ? "auto" : 0)} 0;
  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

const StyledIcon = styled.div<{
  transitMode: Mode;
  $isBottomMargin?: boolean;
}>`
  transform: ${({ transitMode }) =>
    transitMode === "plane" ? "rotate(90deg)" : "initial"};
  width: 24px;
  svg {
    width: ${({ transitMode }) =>
      transitMode === "train" ? iconSize.md : iconSize.sm};
    min-height: 14px;
    height: auto;
    // margin-block and margin-inline are not supported by iOS 13
    margin: ${spacing.xs} auto;
  }
  ${desktopLayout} {
    width: 24px;
    svg {
      width: ${iconSize.xxl};
      min-height: ${iconSize.sm};
      margin-bottom: ${(props) =>
        props.$isBottomMargin ? spacing.sm : undefined};
    }
  }
`;

const WalkContainer = styled.div`
  height: 100%;

  svg {
    height: 100%;
  }
`;

const CompactDottedTimelineContainer = styled.div`
  height: 40px;
  width: 24px;
  margin: auto 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const ferryColor = escape(transitColor.ferry);

const FerryContainer = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 50'%3E%3Cpath fill='none' stroke='${ferryColor}' stroke-width='3.5' stroke-linejoin='round' d='M 11.25,-5 12.75,0 11.25,5 12.75,10 11.25,15 12.75,20 11.25,25 12.75,30 11.25,35 12.75,40 11.25,45 12.75,50 11.25,55' %3E%3C/path%3E%3C/svg%3E");
  background-size: 100%;
  background-position: bottom;
  width: 24px;
  min-height: 48px;
  height: 100%;
`;

const LandOrPLaneContainer = styled.div<{ isLongerTimelineLine?: boolean }>`
  height: ${(props) => (props.isLongerTimelineLine ? "110%" : "100%")};
  svg {
    height: 100%;
  }
`;

const TimelineReducer = styled.div`
  position: relative;
  overflow: visible;
  width: 100%;
  flex-grow: 1;
  svg {
    // Extra 6px needed to offset margin in Timeline origin parent wrapper
    height: calc(100% + 6px);
    position: absolute;
    width: 100%;
  }
`;

const TimelineReducerWalk = styled.div`
  position: relative;
  overflow: visible;
  width: 100%;
  svg {
    position: absolute;
  }
`;
const InterchangeSVG = styled.svg`
  // To hide the overflow of the previous line,
  // the circle inside of the SVG must have a solid bg
  overflow: visible;
  height: 100%;
`;

import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useScroll } from "src/ScrollContext";
import { Icon } from "src/components/Icon/Icon";
import { ArrowForward } from "src/svg/ArrowForward";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import {
  useNavigateToHotelsPage,
  useNavigateToTripsHotels,
} from "src/utils/hooks/useNavigateToHotelsPage";
import useSearch from "src/utils/hooks/useSearch";
import styled from "styled-components";
import { useLayout } from "src/utils/hooks/useLayout";
import { useFeature } from "src/feature/useFeature";
import { AccomPromoLoggingCategory } from "src/analytics/analyticsEventTypes";
import { useKayakExitOnHExEntry } from "src/domain/HotelsScreen/utils-exit-to-provider";
import { sendAnalyticsInteractionEvent } from "../../../analytics/sendAnalyticsInteractionEvent";
import { sendAnalyticsNonInteractionEvent } from "../../../analytics/sendAnalyticsNonInteractionEvent";
import BookingCom from "../../../svg/logos/BookingCom.svg?react";
import { Expedia } from "../../../svg/logos/Expedia";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  logosWidth,
  spacing,
} from "../../../theme";
import { useIsInViewport } from "../../../utils/hooks/useIsInViewport";
import { HotelProviderKind } from "../../../utils/types/accommodationProviders";
import { Timeline } from "../Timeline";
import { AccommodationPromoCell } from "./AccommodationPromoCell";
import messages from "./TroniconTimelineAccommodationPromo.messages";

type Props = {
  cell: AccommodationPromoCell;
  loggingFrom: AccomPromoLoggingCategory;
  showCardLayout?: boolean;
  className?: string;
  showTransfer?: boolean;
};

export function TroniconTimelineAccommodationPromo({
  cell,
  loggingFrom,
  showTransfer,
  showCardLayout,
  className,
}: Props) {
  const hexIsKayak = useFeature("HExIsKayak");
  const intl = useIntl();
  const copyPreposition = cell.useNearbyCopy ? "Near" : "In";
  const calloutMessage = messages[`findHotels${copyPreposition}`];
  const { navigateToHotels } = useNavigateToHotelsPage();
  const navigateToTripsHotels = useNavigateToTripsHotels();
  const { setScrollTop } = useScroll();
  const { origin, destination } = useSearch();
  const handleKayakExit = useKayakExitOnHExEntry();
  const isTripsScreenAsCoreFullExperience = useIsTripsAsCoreFullExperience();
  const layout = useLayout();
  const isStackedNavigationAccom =
    useFeature("StackedNavigationAccom") && layout === "desktop";

  const accomCtaMessage = intl.formatMessage(messages.findHotelsCta);

  // We want to see when an accommodation promo gets into view but only fire the log once.
  const accommodationPromoRef = useRef<HTMLDivElement>(null);
  const accommodationPromoInViewport = useIsInViewport(accommodationPromoRef);
  const [hasAccommodationPromoBeenViewed, setHasAccommodationPromoBeenViewed] =
    useState(false);

  function handleClick() {
    const gaLoggingCategory =
      loggingFrom === "Schedule" ? "ScheduleDetails" : loggingFrom;

    sendAnalyticsInteractionEvent(gaLoggingCategory, "Click:HotelPromo");
    setScrollTop(0);

    if (hexIsKayak) {
      handleKayakExit({
        lat: destination?.lat,
        lng: destination?.lng,
        canonicalName: destination?.canonicalName,
      });
    } else {
      if (isTripsScreenAsCoreFullExperience) {
        if (isStackedNavigationAccom) {
          navigateToTripsHotels();
        } else {
          navigateToHotels({
            originCanonical: origin?.canonicalName,
            destinationCanonical: destination?.canonicalName,
          });
        }
        sendAnalyticsInteractionEvent("TripPlanner", "Click:HotelPromo");
      } else {
        navigateToHotels();
      }
    }
  }

  useEffect(() => {
    if (accommodationPromoInViewport && !hasAccommodationPromoBeenViewed) {
      sendAnalyticsNonInteractionEvent(
        "Screen",
        "AccomPromoVisible",
        `ap0:${cell.provider}`
      );
      setHasAccommodationPromoBeenViewed(true);
    }
  }, [
    accommodationPromoInViewport,
    cell.id,
    cell.provider,
    hasAccommodationPromoBeenViewed,
  ]);

  return (
    <AccommodationWrapper
      showCardLayout={showCardLayout}
      extraPadding={!showCardLayout && loggingFrom === "Schedule"}
      ref={accommodationPromoRef}
      onClick={handleClick}
      className={className}
    >
      <Timeline type="accommodation" transitMode="train" />
      <LeftAligned>
        <PrimaryCallout>
          <span>
            {intl.formatMessage(calloutMessage, { location: cell.placeName })}
          </span>
          <AccomCta>
            <span>{accomCtaMessage}</span>
            <Icon size="sm">
              <ArrowForward tint="pink" title="open-hotels-arrow" />
            </Icon>
          </AccomCta>
        </PrimaryCallout>
      </LeftAligned>
    </AccommodationWrapper>
  );
}

export function ProviderLogo(props: { provider?: HotelProviderKind }) {
  // It's rare that provider will be null but
  // we fall back to bookingCom because that's what the backend does.
  return props.provider === "Expedia" ? (
    <ExpediaWrapper>
      <Expedia />
    </ExpediaWrapper>
  ) : (
    <BookingComWrapper>
      <BookingCom />
    </BookingComWrapper>
  );
}

const AccommodationWrapper = styled.div<{
  showCardLayout?: boolean;
  extraPadding?: boolean;
}>`
  display: flex;
  padding: ${spacing.lg} ${spacing.xl};
  gap: ${spacing.xl};
  cursor: pointer;

  ${(props) => {
    if (props.showCardLayout) {
      return `
          border: 1px solid ${color.grey2};
          border-radius: ${borderRadius.md};
          `;
    }
  }}

  ${({ extraPadding }) =>
    extraPadding &&
    `padding-left: calc(${logosWidth} + ${spacing.xxl} + ${spacing.xl});
    `}

  &:active {
    background-color: ${color.grey1};
  }
`;

const BookingComWrapper = styled.div`
  display: inline;
  svg {
    height: 16px;
    margin-bottom: -4px;
    margin-left: -11px;
  }
`;

const ExpediaWrapper = styled.div`
  display: inline;
  svg {
    height: 16px;
    margin-bottom: -3px;
  }
`;

const LeftAligned = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: ${lineHeight.snug};
`;

const PrimaryCallout = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: ${fontSize.h5};
  font-weight: ${fontWeight.bold};
  column-gap: ${spacing.md};
`;

const AccomCta = styled.div`
  display: flex;
  align-items: center;
  color: ${color.pink};
  gap: ${spacing.md};
`;

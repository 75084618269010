import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { ONE_DAY_IN_MILLISECONDS } from "src/utils/conversions/time";
import { SupportedCurrencyCode } from "src/utils/currency";
import { SupportedLanguageCode } from "src/utils/language";
import { useApiConfig } from "../../api/ApiConfigProvider";
import { getUserEndpoint } from "../../api/endpoints";
import { getSession, isLoggedIn } from "./session";
import { logFetchError } from "./log";

export type UserProfile = {
  id: string;
  name: string;
  profileCurrency: SupportedCurrencyCode;
  isKilometers: boolean;
  profileLanguage: SupportedLanguageCode;
  optedInMarketingEmails: boolean;
  is24h: boolean;
  emails?: { email: string; isPrimary: boolean }[];
};

export type UserProfileUpdateValues = {
  name?: string;
  profileCurrency?: string;
  isKilometers?: boolean;
  profileLanguage?: string;
  optedInMarketingEmails?: boolean;
  is24h?: boolean;
};

export function useUserProfile(setLoadingSession: (loading: boolean) => void) {
  const apiConfig = useApiConfig();
  const { data } = useQuery({
    queryKey: ["user-query-key"],
    gcTime: ONE_DAY_IN_MILLISECONDS,
    staleTime: ONE_DAY_IN_MILLISECONDS,
    enabled: isLoggedIn(),
    queryFn: () => getSession({ apiConfig }, setLoadingSession),
  });
  return data;
}

export function useUploadNewUserProfileValues() {
  const apiConfig = useApiConfig();
  const userEndpoint = getUserEndpoint(apiConfig, "/me");

  const uploadNewUserProfileValues = useCallback(
    async (
      profile: UserProfileUpdateValues
    ): Promise<UserProfile | undefined> => {
      try {
        const result = await fetch(`${userEndpoint}`, {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(profile),
        });

        // Valid update, update our cached profile data
        const updatedProfile = await result.json();
        return updatedProfile;
      } catch (error) {
        logFetchError(error);
        throw error;
      }
    },
    [userEndpoint]
  );

  return uploadNewUserProfileValues;
}

export function getUserEmail(user?: UserProfile): string | undefined {
  if (user?.emails === undefined) return undefined;
  return (
    user.emails.find((email) => email.isPrimary)?.email || user.emails[0].email
  );
}

import { encodeSearchParamsForUrl } from "../encodeSearchParamsForUrl";
import { TypedLocation } from "./useTypedLocation";

export function useDisableAdsUrlParams() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("disableAds") === "true";
}

export function removeDisabledAdsUrlParams(location: TypedLocation) {
  const urlParams = new URLSearchParams(location.search);
  urlParams.delete("disableAds");
  location.search = encodeSearchParamsForUrl(urlParams) ?? location.search;
  return location.search;
}

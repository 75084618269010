import { defineMessages } from "react-intl";

const messages = defineMessages({
  showMoreResults: {
    id: "destination.showMoreResults",
    description: "Button label for showing more search results",
    defaultMessage: "Show {count} more options",
  },
  showLessResults: {
    id: "destination.showLessResults",
    description: "Button label for showing less search results",
    defaultMessage: "Show less options",
  },
  transportOptions: {
    id: "destination.transportOptions",
    description: "Heading for transport options",
    defaultMessage: "Getting here from {origin}",
  },
});

export default messages;

import { defineMessages } from "react-intl";

const messages = defineMessages({
  appName: {
    id: "appName",
    defaultMessage: "Rome2Rio app",
    description: "This is the name of the app, Rome2Rio",
  },
  description: {
    id: "head.description",
    defaultMessage:
      "Discover how to get anywhere by searching plane, train, bus, car and ferry routes",
    description: "Meta description for Google search",
  },
  hotelDescription: {
    id: "head.hotelDescription",
    defaultMessage:
      "Accommodation near {destination} – everything from luxury pools, gyms and transfers to walking distance and cheap stays.",
    description: "Meta description for Google search",
  },
  pageTitle: {
    id: "head.pageTitle",
    defaultMessage: "{origin} to {destination}",
    description:
      "Message that shows at the top of the page in the tab e.g. London to Leeds",
  },
  resultsSectionTitle: {
    id: "head.resultsSectionTitle",
    defaultMessage: "Search results panel",
    description:
      "title for left panel with search results to improve accessibility",
  },
  mapLoading: {
    id: "mapLoading",
    defaultMessage: "Map loading",
    description:
      "A map preview image that displays when the map is still loading",
  },
});

export default messages;

import { isDebugBySearchParams } from "src/utils/isDebugBySearchParams";
import { isContentTest, isPreview } from "../utils/host";

export const PossibleFeaturesMap = {
  Debug: [false, true],
  LogAnalyticsEventsToConsole: [false, true],
  FillAds: ["default", "never", "alwaysWithTestAds"],

  // Don't delete, required for content-test
  SkipRoutes: [false, true],
  SeeLessSchedulesFeature: [false, true],
  UseStaticConnectedSchedules: [
    "never",
    "FullyScheduleableNonBookableRoutes",
    "FullyBookableRoutesFallback",
    "FirstOrLastWalkingSegmentRoutes",
  ],
  SkipNonBookableRoutes: [false, true],
  ForceUseLiveScheduleFallback: [false, true],
  UseDarkMode: [false, true],
  GoogleCustomButton: [false, true],
  ShortNavFeature: [false, true],
  KayakInlineAds: [false, true],
  DatesInTripPlanner: [false, true],
  SchedulesFeedbackButton: [false, true],
  GAMConsentManagement: [false, true],
  JayrideTransfersPromos: [false, true],
  StrikethroughDeals: ["baseline", "red", "green"],
  USStateConsentManagement: [false, true],
  DesktopTwoColumnLayout: [false, true],
  HotelMapPinRework: ["none", "cluster", "onlyBest"],
  DestinationRecommendations: [false, true],
  ServiceSummaryImprovements: [false, true],
  DelayPrebidUntilConsentReady: [false, true],
  DesktopInlineSerpAd: [false, true],
  HExDefaultSortOrder: ["default", "priceLow", "reviewHigh"],
  CmpDefaultConsent: [false, true],
  StackedNavigationAccom: [false, true],
  TripURL: [false, true],
  ApplyRouteListReordering: [false, true],
  TripsV2OnMobile: [false, true],
  AdaraIntegration: [false, true],
  ClearerMessaging: [false, true],
  TimeOfDayFilter: [false, true],
  HExScrollNudge: [false, true],
  PreDestinationPages: ["baseline", "card", "transitInfo"],
  ClearerMessagingQA: [false, true],
  SimpleSearchV2: [false, true],
  HExRefineSearch: [
    "baseline",
    "suburbTop",
    "suburbMid",
    "attractionTop",
    "attractionMid",
  ],
  Daytrip: ["baseline", "landingPage", "configurator"],
  HExIsKayak: [false, true],
} as const;

// Note: We do not include the 'test' environment as isDevelopment as all features here are presentational only
const isDevelopment = import.meta.env.MODE === "development";

export const defaultFeatureConfig: FeatureConfig = {
  Debug: isDevelopment || isPreview(),
  LogAnalyticsEventsToConsole:
    isDevelopment || isPreview() || isDebugBySearchParams(),
  FillAds: isDevelopment || isPreview() ? "alwaysWithTestAds" : "default",
  SkipRoutes: !isContentTest(),
  SeeLessSchedulesFeature: false,
  UseStaticConnectedSchedules: "FirstOrLastWalkingSegmentRoutes",
  SkipNonBookableRoutes: true,
  ForceUseLiveScheduleFallback: false,
  UseDarkMode: false,
  // For further information on this feature, see: https://rome2rio.atlassian.net/wiki/spaces/~630c49a1d8850343ef57e7e9/pages/2709291041/Google+Sign+In+for+Explore+GAPI+vs+GSI
  GoogleCustomButton: false,
  ShortNavFeature: false,
  KayakInlineAds: true,
  DatesInTripPlanner: false,
  SchedulesFeedbackButton: false,
  GAMConsentManagement: false,
  JayrideTransfersPromos: false,
  StrikethroughDeals: "baseline",
  USStateConsentManagement: false,
  DesktopTwoColumnLayout: false,
  HotelMapPinRework: "none",
  DestinationRecommendations: false,
  ServiceSummaryImprovements: false,
  DelayPrebidUntilConsentReady: !isDevelopment && !isPreview(),
  DesktopInlineSerpAd: false,
  HExDefaultSortOrder: "default",
  CmpDefaultConsent: isDevelopment || isPreview(),
  StackedNavigationAccom: false,
  TripURL: false,
  ApplyRouteListReordering: true,
  TripsV2OnMobile: false,
  AdaraIntegration: false,
  ClearerMessaging: false,
  TimeOfDayFilter: false,
  HExScrollNudge: false,
  PreDestinationPages: "baseline",
  ClearerMessagingQA: false,
  SimpleSearchV2: false,
  HExRefineSearch: "baseline",
  Daytrip: "baseline",
  HExIsKayak: false,
};

export type FeatureName = keyof typeof PossibleFeaturesMap;

export type FeatureValue<F extends FeatureName> =
  (typeof PossibleFeaturesMap)[F][number];

export type FeatureConfig = {
  [F in FeatureName]: FeatureValue<F>;
};

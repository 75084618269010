import { useQuery } from "@tanstack/react-query";
import { PassengerDetails } from "src/PassengerDetailsProvider";
import { SchedulesResponse } from "src/api/SchedulesResponse";
import { SearchResponse } from "src/api/SearchResponse";
import { Mode } from "src/utils/types/mode";
import { pushCrimtanEvent } from "./pushDataLayerEvent";

type CrimtanParams = {
  searchResponse?: SearchResponse;
  isCrimtanEnabled?: boolean;
  schedulesResponse?: SchedulesResponse;
  passengers?: PassengerDetails;
  transitMode?: Mode;
};

const useCrimtan = ({
  searchResponse,
  isCrimtanEnabled,
  schedulesResponse,
  passengers,
  transitMode,
}: CrimtanParams) => {
  window.cmtnDataLayer = window.cmtnDataLayer || [];

  useQuery({
    queryKey: [
      "useCrimtan",
      isCrimtanEnabled,
      searchResponse?.request,
      schedulesResponse,
    ],
    queryFn: () => {
      const customDimensions = searchResponse?.analytics?.custom_dimensions;

      const origin = customDimensions?.dimension21;
      const destination = customDimensions?.dimension22;

      if (
        !isCrimtanEnabled ||
        !origin ||
        typeof origin !== "string" ||
        !destination ||
        typeof destination !== "string" ||
        (transitMode && schedulesResponse === undefined)
      ) {
        return "";
      }

      pushCrimtanEvent({
        event: "transportSearch",
        origin,
        destination,
        travelMode: transitMode,
        travelCarriers: getCarriers(schedulesResponse) ?? [],
        outboundDate: schedulesResponse?.request.outboundDateTime,
        inboundDate: schedulesResponse?.request.inboundDateTime,
        passengerCount: passengers?.length,
      });

      // Queries must return a value.
      return "";
    },
    refetchOnWindowFocus: false,
  });
};

const getCarriers = (
  schedulesResponse?: SchedulesResponse
): string[] | undefined => {
  if (!schedulesResponse?.carriers) return;

  return schedulesResponse?.carriers.map((c) => c.name);
};

export default useCrimtan;

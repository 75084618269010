import { QueryClient } from "@tanstack/react-query";
import { PrefetchData } from "./PrefetchData";
import { ONE_DAY_IN_MILLISECONDS } from "./utils/conversions/time";
import { getCacheKeyForGeocode } from "./utils/hooks/useGeocode";
import { getCacheKeyForSearch, processSearchResponse } from "./utils/search";

export function initPrefetchedData(
  queryClient: QueryClient,
  prefetchContent?: string
): string | undefined {
  const data = prefetchContent ? JSON.parse(prefetchContent) : undefined;

  if (data === undefined) return;

  initCachedGeocode(data, queryClient);
  return initCachedSearchResponse(data, queryClient);
}

function initCachedSearchResponse(
  data: PrefetchData,
  queryClient: QueryClient
): string | undefined {
  if (!data.searchResponse || !data.geocoded || !data.languageCode) return;

  // The prefetch pulls the geocoded terms out of the search response.
  const initialOriginPlace = data.geocoded[0];
  const initialDestinationPlace = data.geocoded[1];

  const searchKey = getCacheKeyForSearch(
    initialOriginPlace.canonicalName,
    initialDestinationPlace.canonicalName,
    data.searchResponse.request.currencyCode!,
    data.languageCode
  );

  queryClient.setQueryDefaults(searchKey, {
    gcTime: ONE_DAY_IN_MILLISECONDS,
    staleTime: ONE_DAY_IN_MILLISECONDS,
  });
  queryClient.setQueryData(searchKey, data.searchResponse);

  processSearchResponse(
    data.searchResponse,
    data.geocoded[0],
    data.geocoded[1]
  );

  return data.searchResponse.request?.requestId;
}

function initCachedGeocode(data: PrefetchData, queryClient: QueryClient) {
  if (!data.geocoded || !data.terms || !data.languageCode) return;

  // Language code is picked up by URL requested, will be placed in prefetch data
  const languageCode = data.languageCode;

  const originGeocoded = data.geocoded[0];
  const originTerm = data.terms[0];

  const destinationGeocoded = data.geocoded[1];
  const destinationTerm = data.terms[1];

  // If the original URL fetch contained a search term that was not a canonical, the search-response-data returned
  // will have the geocoded result, so we can pre-fill the straight canonical lookup.
  // The URL eventually be changed to the canonical term, so to save an extra geocode, we should prefil the canonical search term too.

  // Origin Terms/Canonicals

  if (originTerm && originGeocoded) {
    queryClient.setQueryDefaults(
      getCacheKeyForGeocode(originTerm, languageCode),
      {
        gcTime: ONE_DAY_IN_MILLISECONDS,
        staleTime: ONE_DAY_IN_MILLISECONDS,
      }
    );
    queryClient.setQueryData(
      getCacheKeyForGeocode(originTerm, languageCode),
      originGeocoded
    );

    queryClient.setQueryDefaults(
      getCacheKeyForGeocode(originGeocoded.canonicalName, languageCode),
      {
        gcTime: ONE_DAY_IN_MILLISECONDS,
        staleTime: ONE_DAY_IN_MILLISECONDS,
      }
    );
    queryClient.setQueryData(
      getCacheKeyForGeocode(originGeocoded.canonicalName, languageCode),
      originGeocoded
    );
  }

  // Destination Terms/Canonicals
  if (destinationTerm && destinationGeocoded) {
    queryClient.setQueryDefaults(
      getCacheKeyForGeocode(destinationTerm, languageCode),
      {
        gcTime: ONE_DAY_IN_MILLISECONDS,
        staleTime: ONE_DAY_IN_MILLISECONDS,
      }
    );
    queryClient.setQueryData(
      getCacheKeyForGeocode(destinationTerm, languageCode),
      destinationGeocoded
    );

    queryClient.setQueryDefaults(
      getCacheKeyForGeocode(destinationTerm, languageCode),
      {
        gcTime: ONE_DAY_IN_MILLISECONDS,
        staleTime: ONE_DAY_IN_MILLISECONDS,
      }
    );
    queryClient.setQueryData(
      getCacheKeyForGeocode(destinationGeocoded.canonicalName, languageCode),
      destinationGeocoded
    );
  }
}

import { initializeTcDataAnalyticsLogging } from "@rome2rio/tcf-api";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useReducedMotion } from "@react-spring/web";
import { useFeature } from "src/feature/useFeature";
import { View } from "./analytics/generateExitPoint/generateExitPoint";
import messages from "./App.messages";

import { registerTagManagerConsent } from "./analytics/googleTagManagerScript";
import { sendAnalyticsNonInteractionEvent } from "./analytics/sendAnalyticsNonInteractionEvent";
import { useInitializeAppleSignin } from "./auth/sdks/AppleAuthSdk";
import { useInitializeGoogleSignin } from "./auth/sdks/GoogleAuthSdkHooks";
import { SkipLink } from "./components/AccessibilityLink/SkipLink";
import { BottomNavbar } from "./components/BottomNavbar/BottomNavbar";
import {
  DefaultErrorBoundary,
  MapErrorFallback,
} from "./components/DefaultErrorBoundary/DefaultErrorBoundary";
import { Layout } from "./components/Layout/Layout";
import { LazyTripPlannerMap } from "./components/Map/TripPlannerMap/LazyTripPlannerMap";
import { RightRail } from "./components/RightRail/RightRail";
import { VisuallyHidden } from "./components/VisuallyHidden/VisuallyHidden";
import { AutocompleteKind } from "./domain/AutocompleteScreen/AutocompleteScreen";
import { HotelsProvider } from "./domain/HotelsScreen/HotelsContext";
import { LargeSearchBar } from "./domain/LargeSearchBar/LargeSearchBar";
import { ReturnFlowStateProvider } from "./domain/SegmentScreen/ReturnFlowStateProvider";
import { TravelSearchSettingsProvider } from "./domain/SegmentScreen/TravelSearchSettingsProvider";
import { TopNav } from "./domain/TopNav/TopNav";
import { TripPlannerProvider } from "./domain/TripPlanner/TripPlannerProvider";
import { UNSAFE_useConfigureFeatureEscapeHatch } from "./feature/UNSAFE_useConfigureFeatureEscapeHatch";
import { HoveredIndexProvider } from "./HoveredIndexProvider";
import { PaneContent } from "./PaneContent";
import { ScrollContext } from "./ScrollContext";
import { duration } from "./theme";
import { useCachedResponse } from "./utils/hooks/useCachedResponse";
import { useGetPermaLink } from "./utils/hooks/useGetPermaLink";
import { useHash } from "./utils/hooks/useHash";
import { useHeadMeta } from "./utils/hooks/useHeadMeta";
import { useHeadTitle } from "./utils/hooks/useHeadTitle";
import { initializeHotjarOnConsent } from "./utils/hooks/useHotjar";
import { useIsTripScreen } from "./utils/hooks/useIsTripScreen";
import { useLanguageAttribute } from "./utils/hooks/useLanguageAttribute";
import { desktopLayout, useLayout } from "./utils/hooks/useLayout";
import { useIsHotelsUrlDeeplink } from "./utils/hooks/useNavigateToHotelsPage";
import { usePath } from "./utils/hooks/usePath";
import useSearch from "./utils/hooks/useSearch";
import { useSetFeatureCookie } from "./utils/hooks/useSetFeatureCookie";
import { useSwitchToHotelsNear } from "./utils/hooks/useSwitchToHotelsNear";
import { useTypedLocation } from "./utils/hooks/useTypedLocation";
import logBreadcrumbs from "./utils/logBreadcrumbs";
import logContext from "./utils/logContext";
import { useDetectAdBlocker } from "./utils/useDetectAdBlocker";
import { registerCrimtanConsent } from "./data-partners/addCrimtan";
import { useDisableAdsUrlParams } from "./utils/hooks/useDisableAdsUrlParams";
import { LazyTripSearchBar } from "./domain/TripPlanner/TripSearchBar/LazyTripSearchBar";
import richmondMapImage from "./Richmond.webp";
import LazyHotelLargeSearchBar from "./domain/HotelsScreen/HotelSearch/LazyHotelLargeSearchBar";
import { TwoColumnLayoutProvider } from "./domain/TripPlanner/hooks/useTwoColumnLayoutProvider";
import color from "./design-system/tokens/color";
import { registerAdaraConsent } from "./data-partners/adara/registerAdaraConsent";
import { AnalyticsEventCategory } from "./analytics/analyticsEventTypes";
import { useMarker } from "./utils/hooks/useMarker";

const LazySearchMap = lazy(async () => {
  logBreadcrumbs("Map", "Requesting Map chunk");
  return await import(
    /* webpackChunkName: "Map" */
    "./components/Map/Map"
  );
});

export type LocationState = {
  autocomplete?: AutocompleteKind;
  searchBar?: "static" | "edit";
  carrierCode?: string;
  schedulePicker?: boolean;
  scheduleIndex?: number;
  ticketPickerView?: View; // The View that opened the ticket picker so that exit logging is assigned correctly.
  highlightedTab?: MobileTabs;
  preferencesScreen?: "main" | "currency" | "language" | "distance" | "debug";
};

export type MobileTabs = "search" | "preferences" | "hotels" | "trips";

export function App() {
  UNSAFE_useConfigureFeatureEscapeHatch();
  const intl = useIntl();
  const location = useTypedLocation();
  const { returnsFlowLocation } = location.state ?? {};
  const { origin, destination, searchResponse } = useSearch();
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const permalink = useGetPermaLink();
  const isTripScreen = useIsTripScreen();
  const Map = isTripScreen ? LazyTripPlannerMap : LazySearchMap;
  usePath(origin?.canonicalName, destination?.canonicalName);
  useHash();
  useHeadTitle(origin?.shortName, destination?.shortName);
  const isHotelsScreen = useIsHotelsUrlDeeplink();
  useHeadMeta(
    isHotelsScreen
      ? intl.formatMessage(messages.hotelDescription, {
          destination: destination?.shortName,
        })
      : intl.formatMessage(messages.description)
  );
  useLanguageAttribute();
  useSwitchToHotelsNear();
  useSetFeatureCookie();
  useInitializeGoogleSignin();
  useInitializeAppleSignin();
  useReducedMotion();
  useMarker();
  const isAdaraEnabled = useFeature("AdaraIntegration");

  useEffect(() => {
    registerTagManagerConsent();
    initializeTcDataAnalyticsLogging((category, action, label, value) =>
      sendAnalyticsNonInteractionEvent(
        category as unknown as AnalyticsEventCategory,
        action,
        label,
        value
      )
    );
    initializeHotjarOnConsent();
  }, []);

  useEffect(() => {
    registerCrimtanConsent(
      searchResponse?.analytics?.interest_data?.Crimtan?.enabled
    );
  }, [searchResponse?.analytics?.interest_data?.Crimtan?.enabled]);

  useEffect(() => {
    if (isAdaraEnabled) {
      registerAdaraConsent();
    }
  }, [isAdaraEnabled]);

  const adBlockerDetected = useDetectAdBlocker();

  useEffect(() => {
    if (adBlockerDetected) {
      sendAnalyticsNonInteractionEvent("App", "AdBlockerDetected");
    }
  }, [adBlockerDetected]);

  const layout = useLayout((layout, lastLayout) => {
    logBreadcrumbs("Layout", `Layout change: ${lastLayout} -> ${layout}`);
  });

  // If the user pastes a saved url, we need to default to the correct tab on mobile.
  function chooseDefaultTabHighlight() {
    if (isTripScreen) {
      return "trips";
    } else if (isHotelsScreen) {
      return "hotels";
    }
    return "search";
  }

  const geocodeOrigin = origin?.canonicalName;
  const geocodeDestination = destination?.canonicalName;
  const responseOrigin = searchResponse?.request?.oName;
  const responseDestination = searchResponse?.request?.dName;
  useEffect(() => {
    logContext({
      layout: layout,
      responseOrigin: responseOrigin,
      responseDestination: responseDestination,
      geocodeOrigin: geocodeOrigin,
      geocodeDestination: geocodeDestination,
      permalink: permalink,
    });
  }, [
    layout,
    responseOrigin,
    responseDestination,
    geocodeOrigin,
    geocodeDestination,
    permalink,
  ]);

  // On mobile default to using the <html /> element for scrolling
  const scrollRef = useRef<HTMLElement>(document.documentElement);
  const getScrollTop = useCallback(() => scrollRef.current?.scrollTop ?? 0, []);
  const setScrollTop = useCallback((top: number) => {
    if (scrollRef.current) scrollRef.current.scrollTop = top;
  }, []);

  const highlightedTab =
    location.state?.highlightedTab ?? chooseDefaultTabHighlight();

  // Cache the search response for the map so that when a new request
  // is made the map doesn't get refreshed.
  const cachedResponse = useCachedResponse(searchResponse);

  const UseScheduleDetailScreen =
    location.state?.openScheduleIndex !== undefined && !isHotelsScreen;

  const isMapEnabled = layout !== "mobile" || isHotelsScreen;

  // Once the map is loaded, keep it loaded even when it is not visible
  // so that we can avoid reloading the map which costs us money.
  const shouldRenderMap = !!((isMapEnabled || isMapLoaded) && cachedResponse);

  useEffect(() => {
    function onMapApiLoaded() {
      setIsMapLoaded(true);
    }
    window.addEventListener("mapApiLoaded", onMapApiLoaded);

    return () => {
      window.addEventListener("mapApiLoaded", onMapApiLoaded);
    };
  }, []);

  const hideSearchBar = isTripScreen || isHotelsScreen;
  const isShowTripSearchBar = isTripScreen && layout !== "mobile";

  // During an experiment where the popunder will send users to the hotels screen,
  // we do not want to serve any ads on the hotels screen.
  const isHExPopunderDisabledAds = useDisableAdsUrlParams();

  const shouldShowRightRail =
    layout === "desktop" && !(isHotelsScreen && isHExPopunderDisabledAds);

  return (
    <ScrollContext.Provider
      value={{
        getScrollTop,
        setScrollTop,
        scrollRef,
      }}
    >
      <TravelSearchSettingsProvider>
        <HoveredIndexProvider>
          <TwoColumnLayoutProvider>
            <TripPlannerProvider>
              <ReturnFlowStateProvider
                returnsFlowLocation={returnsFlowLocation}
              >
                <HotelsProvider>
                  <AppContainer>
                    <VisuallyHidden>
                      <h1>{intl.formatMessage(messages.appName)}</h1>
                    </VisuallyHidden>
                    {layout !== "mobile" && (
                      <Header>
                        <SkipLink />
                        <TopNav />
                        {!hideSearchBar ? <LargeSearchBar /> : null}
                        {isHotelsScreen ? <LazyHotelLargeSearchBar /> : null}
                        {isShowTripSearchBar ? <LazyTripSearchBar /> : null}
                      </Header>
                    )}
                    <DefaultErrorBoundary defaultMessage>
                      <Layout
                        ref={(el) => {
                          if (el && layout === "desktop") {
                            scrollRef.current = el;
                          }
                        }}
                        leftPane={
                          <PaneContent
                            highlightedTab={highlightedTab}
                            layout={layout}
                            sectionTitle={intl.formatMessage(
                              messages.resultsSectionTitle
                            )}
                            openScheduleIndex={
                              location.state?.openScheduleIndex
                            }
                            returnsFlowLocation={returnsFlowLocation}
                          />
                        }
                        background={
                          isMapEnabled ? (
                            <>
                              <DefaultErrorBoundary
                                ErrorFallback={MapErrorFallback}
                              >
                                <Suspense fallback={<div />}>
                                  {shouldRenderMap && (
                                    <Map
                                      searchResponse={cachedResponse}
                                      returnsFlowLocation={returnsFlowLocation}
                                    />
                                  )}
                                </Suspense>
                              </DefaultErrorBoundary>
                              <MapLoadingImg
                                // This placeholder is 400*400px.
                                // Any image in the viewport exceeding 160,000px will overtake this as the Largest Contentful Element.
                                src={richmondMapImage}
                                alt={intl.formatMessage(messages.mapLoading)}
                                aria-hidden={isMapLoaded}
                                $hide={isMapLoaded}
                              />
                            </>
                          ) : null
                        }
                        rightRail={shouldShowRightRail && <RightRail />}
                      />
                    </DefaultErrorBoundary>

                    {layout === "mobile" && !UseScheduleDetailScreen && (
                      <StyledBottomNavbar
                        active={highlightedTab}
                        setScrollTop={setScrollTop}
                      />
                    )}
                  </AppContainer>
                </HotelsProvider>
              </ReturnFlowStateProvider>
            </TripPlannerProvider>
          </TwoColumnLayoutProvider>
        </HoveredIndexProvider>
      </TravelSearchSettingsProvider>
    </ScrollContext.Provider>
  );
}

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopLayout} {
    height: 100vh;
  }
`;

const Header = styled.header`
  position: relative;
  z-index: 10;
  box-shadow: 0px 1px 3px rgba(31, 30, 30, 0.24);
  background-color: ${color.bg.fill.fill};
`;

const StyledBottomNavbar = styled(BottomNavbar)`
  position: fixed;
  bottom: 0;
`;

const MapLoadingImg = styled.img<{ $hide: Boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: ${(props) => (props.$hide ? 0 : 1)};
  filter: blur(12px);
  transform: scale(1.1);
  transform-origin: center;
  transition: opacity ${duration.lg}ms ease-in-out;
  pointer-events: none;
`;

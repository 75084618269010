import { defineMessages } from "react-intl";

export const messages = defineMessages({
  results: {
    id: "navigationBreadcrumbs.results",
    defaultMessage: "Results",
    description:
      "The text on a link to take user back to search results screen",
  },
  route: {
    id: "navigationBreadcrumbs.route",
    defaultMessage: "Route",
    description: "The text on a link to take user back to the route screen",
  },
  tripSummary: {
    id: "navigationBreadcrumbs.tripSummary",
    defaultMessage: "Trip Summary",
    description:
      "The text to display in the navigation breadcrumbs to show you're on the trip summary pane",
  },
  hotel: {
    id: "navigationBreadcrumbs.hotel",
    defaultMessage: "Hotels",
    description:
      "The text to display in the navigation breadcrumbs to show you're on the hotel pane",
  },
  tripPlan: {
    id: "navigationBreadcrumbs.tripPlan",
    defaultMessage: "Trip Plan",
    description:
      "The text on a link to take user back to the trip planner screen",
  },
  destination: {
    id: "navigationBreadcrumbs.destination",
    defaultMessage: "{destination} overview",
    description:
      "The text on a link to take user back to the destination screen",
  },
});

import { ReactElement, useCallback, useEffect, useState } from "react";

import { PaneStack } from "src/components/PaneStack/PaneStack";
import { color, spacing } from "src/theme";
import { SearchOverrideProvider } from "src/utils/hooks/SearchOverrideProvider";
import {
  ReturnStage,
  useTypedLocation,
} from "src/utils/hooks/useTypedLocation";
import styled from "styled-components";
import { useFeature } from "src/feature/useFeature";
import { useLayout } from "src/utils/hooks/useLayout";
import { isValidSegment } from "src/domain/SegmentScreen/useEnsureValidSegment";
import { useIsRouteSkipped } from "src/utils/hooks/useIsRouteSkipped";
import LazyHotelsScreen from "src/domain/HotelsScreen/LazyHotelsScreen";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import {
  getStackedPaneWidth,
  TRIP_PANE_GAP,
  useStackPaneWidth,
} from "src/utils/hooks/useGetStackedMapOffset";
import { Button } from "src/design-system/components/Button/Button";
import { ChevronLeft } from "src/svg/designSystem/ChevronLeft";
import { useIntl } from "react-intl";
import { isValidRoute } from "src/domain/RouteScreen/useEnsureValidRoute";
import { useScroll } from "src/ScrollContext";
import { Icon } from "src/design-system/components/Icon/Icon";
import DestinationScreen from "src/domain/DestinationScreen/DestinationScreen";
import { MemoizedTripPlannerTransport } from "../Transport/TripPlannerTransport";
import { useTripSearchResponse } from "../hooks/useTripSearchResponse";
import { getScreenKey } from "../util/getScreenKey";
import { useTripRouteSegmentIndex } from "../hooks/useTripSegment";
import { useTripRouteIndex } from "../hooks/useTripRoute";
import { useClosePane } from "../hooks/useClosePane";
import { useTwoColumnLayoutContext } from "../hooks/useTwoColumnLayoutProvider";
import { getOriginDestinationFromHash } from "../util/getOriginDestinationFromHash";
import messages from "./StackedNavigation.messages";

type Props = {
  TripPlannerContent: ReactElement;
  transportIndex?: number;
  returnsFlowLocation?: ReturnStage;
};

export function StackedNavigation({
  transportIndex,
  returnsFlowLocation,
  TripPlannerContent,
}: Props) {
  const intl = useIntl();
  const location = useTypedLocation();
  const layout = useLayout();
  const { closePane } = useClosePane();
  const { setScrollTop } = useScroll();
  const screenKey = getScreenKey(location.hash);

  const { tripSearchResponse, tripOrigin, tripDestination } =
    useTripSearchResponse();
  const routeIndex = useTripRouteIndex();
  const routeSegmentIndex = useTripRouteSegmentIndex();
  const isRouteSkipped = useIsRouteSkipped(routeIndex);
  const [validSegment, setValidSegment] = useState<boolean | undefined>();
  const isRoutePane =
    isValidRoute(tripSearchResponse, routeIndex) && !validSegment;
  const isSearchScreen = routeIndex === undefined && screenKey === "transport";
  const isDestinationScreen = screenKey === "destination";
  const isNewTripPaneWidth =
    isRoutePane || isSearchScreen || isDestinationScreen;

  const { isWideTwoColLayout, setIsWideTwoColLayout, setSegmentScreenActive } =
    useTwoColumnLayoutContext();

  useEffect(() => {
    if (validSegment) {
      setSegmentScreenActive(validSegment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSegment]);

  const paneWidth = useStackPaneWidth(screenKey);

  const desktopTwoColumnLayoutFeature =
    useFeature("DesktopTwoColumnLayout") && layout === "desktop";
  const isDesktopTwoColumnLayout =
    desktopTwoColumnLayoutFeature && screenKey === "transport" && validSegment;

  const activePaneIndex = screenKey !== undefined ? 1 : 0;

  // If the active pane index is set to 0, the transport flow is animating out, and a rerender isn't necessary.
  // A rerender of the transport flow as it is animating out is expensive (and jittery), so this check prevents any animation jitter.
  const hideTransportFlow = activePaneIndex === 0 || screenKey !== "transport";

  // This effect resets the scroll position when navigating into the hotels screen.
  useEffect(() => {
    if (screenKey === "hotels") {
      setScrollTop(0);
    }
  }, [screenKey, setScrollTop]);

  const checkValidSegment = useCallback(() => {
    const isValid = isValidSegment(
      tripSearchResponse,
      routeIndex,
      routeSegmentIndex,
      isRouteSkipped
    );

    setValidSegment(isValid);
  }, [isRouteSkipped, routeIndex, routeSegmentIndex, tripSearchResponse]);

  // In order to calculate whether the desktop two column experience should
  // be applied, we first need to check if the segment is valid.
  // We do not render the pane until this has been determined.
  useEffect(() => {
    if (tripSearchResponse !== undefined && activePaneIndex === 1) {
      checkValidSegment();
    }
  }, [checkValidSegment, tripSearchResponse, activePaneIndex]);

  function closePaneClick() {
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Dismiss:StackedNav",
      "backdrop"
    );
    closePane();
  }

  const { origin, destination } =
    getOriginDestinationFromHash(location.hash) ?? {};

  return (
    <PaneStack
      activePaneIndex={activePaneIndex}
      paneGap={TRIP_PANE_GAP}
      paneWidth={
        isDesktopTwoColumnLayout && isWideTwoColLayout
          ? `calc(100% - ${TRIP_PANE_GAP}px)`
          : isNewTripPaneWidth
          ? paneWidth
          : getStackedPaneWidth(getScreenKey(location.hash))
      }
      BottomPane={TripPlannerContent}
      onBackdropClick={closePaneClick}
      isTwoColumnLayout={isDesktopTwoColumnLayout && isWideTwoColLayout}
    >
      <ScreenContainer>
        <SearchOverrideProvider
          value={{
            searchResponse: tripSearchResponse!,
            origin: tripOrigin,
            destination: tripDestination,
          }}
        >
          {isDestinationScreen && (
            <DestinationScreen
              origin={!!destination ? origin : undefined}
              destination={destination ?? origin}
            />
          )}
          {!hideTransportFlow && validSegment !== undefined && (
            <MemoizedTripPlannerTransport
              context="transport"
              transportIndex={transportIndex}
              returnsFlowLocation={returnsFlowLocation}
            />
          )}
          {screenKey === "hotels" && <LazyHotelsScreen />}
        </SearchOverrideProvider>
        {isDesktopTwoColumnLayout && !isWideTwoColLayout && (
          <HideMapButtonWrapper>
            <HideMapButton
              variant="secondary"
              onPress={() => {
                sendAnalyticsInteractionEvent("MapView", "Click:HideMap");
                setIsWideTwoColLayout(!isWideTwoColLayout);
              }}
            >
              <Icon>
                <ChevronLeft tint="primaryOnLight" strokeWidth="4" />
              </Icon>
              {intl.formatMessage(messages.hideMap)}
            </HideMapButton>
          </HideMapButtonWrapper>
        )}
      </ScreenContainer>
    </PaneStack>
  );
}

const HideMapButtonWrapper = styled.div`
  position: absolute;
  top: ${spacing.xxl};
  right: -130px;
  z-index: 10;
  height: 100%;
  pointer-events: none;
`;

const ScreenContainer = styled.div`
  width: 100%;
  background-color: ${color.n20};
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;

const HideMapButton = styled(Button)`
  position: sticky;
  top: ${spacing.xxl};
  pointer-events: auto;
`;

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useQueryClient } from "@tanstack/react-query";
import { useGaConfigUser } from "./auth/hooks/useGaConfigUser";
import { isLoggedIn } from "./auth/utils/session";
import {
  UserProfile,
  UserProfileUpdateValues,
  useUploadNewUserProfileValues,
  useUserProfile,
} from "./auth/utils/user";
import { localeToLanguageCode } from "./utils/conversions/languageCode";
import { setCurrencyCookie, SupportedCurrencyCode } from "./utils/currency";
import {
  DistanceSystem,
  setDistanceSystemCookie,
} from "./utils/distanceSystem";
import { useNavigateToNewLanguage } from "./utils/hooks/useNavigateToNewLanguage";
import { setLanguageCookie, SupportedLanguageCode } from "./utils/language";
import logError from "./utils/logError";
import { setTimeFormatCookie } from "./utils/timeFormat";

export type UserPreferences = {
  prefers12Hour: boolean;
  savePrefers12Hour: Dispatch<boolean>;
  currencyCode: SupportedCurrencyCode;
  saveCurrencyCode: Dispatch<SupportedCurrencyCode>;
  distanceSystem: DistanceSystem;
  saveDistanceSystem: Dispatch<DistanceSystem>;
  language: SupportedLanguageCode;
  saveLanguage: Dispatch<SupportedLanguageCode>;
  user?: UserProfile;
  setUserAndSyncPreferences: (user: UserProfile) => void;
  resetUser: () => void;
  loadingSession: boolean;
  setLoadingSession: Dispatch<SetStateAction<boolean>>;
  updateUserProfile: (values: UserProfileUpdateValues) => Promise<void>;
};

export const UserContext = createContext<UserPreferences | undefined>(
  undefined
);

type Props = PropsWithChildren<{
  initialPrefers12Hour?: boolean;
  initialCurrencyCode?: SupportedCurrencyCode;
  initialDistanceSystem?: DistanceSystem;
}>;

function UserProvider(props: Props) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { navigateToNewLanguage } = useNavigateToNewLanguage();

  // We need to keep track of the loading state of the session based on the session id, because
  // we want to show the loading screen until we get the user info if the user is logged in
  const [loadingSession, setLoadingSession] = useState(() => isLoggedIn());

  // Hooks related to the user session
  // userFromSession is the user from the request session, it will be undefined until the request is done so we need to track it in a state
  const user = useUserProfile(setLoadingSession);
  useGaConfigUser(user);

  const uploadNewUserProfileValues = useUploadNewUserProfileValues();

  // Hooks related to the user preferences
  const [prefers12Hour, setPrefers12Hour] = useState(
    props.initialPrefers12Hour ?? false
  );
  const [currencyCode, setCurrencyCode] = useState(
    props.initialCurrencyCode ?? "USD"
  );
  const [distanceSystem, setDistanceSystem] = useState(
    props.initialDistanceSystem ?? "metric"
  );
  const [language, setLanguage] = useState(localeToLanguageCode(intl.locale));

  // Callbacks related to the user session
  const resetUser = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["user-query-key"] });
    queryClient.setQueryData(["user-query-key"], undefined);
  }, [queryClient]);

  const updateUserProfile = useCallback(
    async (profile: UserProfileUpdateValues) => {
      try {
        setLoadingSession(true);
        const response = await uploadNewUserProfileValues(profile);
        if (response) {
          // When we update the user profile, we need to invalidate the old cached user-profile data, even if the request fails
          queryClient.invalidateQueries({ queryKey: ["user-query-key"] });
          queryClient.setQueryData(["user-query-key"], response);
        }
      } catch (error) {
        logError(error, ["user-accounts"]);
      } finally {
        setLoadingSession(false);
      }
    },
    [uploadNewUserProfileValues, queryClient]
  );
  const syncUserPrefrencesFromSession = useCallback(
    (user: UserProfile) => {
      // Time format
      setPrefers12Hour(!user.is24h);
      setTimeFormatCookie(!user.is24h);
      // Currency
      setCurrencyCode(user.profileCurrency);
      setCurrencyCookie(user.profileCurrency);
      // Distance system
      setDistanceSystem(user.isKilometers ? "metric" : "imperial");
      setDistanceSystemCookie(user.isKilometers ? "metric" : "imperial");
      // Language
      setLanguageCookie(user.profileLanguage);
      setLanguage((prevLanguage) => {
        if (user.profileLanguage !== prevLanguage) {
          navigateToNewLanguage(user.profileLanguage);
        }
        return user.profileLanguage;
      });
    },
    [navigateToNewLanguage]
  );

  // Callbacks related to the user preferences
  const savePrefers12Hour = useCallback(
    (prefers12Hour: boolean) => {
      setPrefers12Hour(prefers12Hour);
      setTimeFormatCookie(prefers12Hour);
      if (user) {
        updateUserProfile({
          is24h: !prefers12Hour,
        });
      }
    },
    [updateUserProfile, user]
  );
  const saveCurrencyCode = useCallback(
    (currencyCode: SupportedCurrencyCode) => {
      setCurrencyCode(currencyCode);
      setCurrencyCookie(currencyCode);
      if (user) {
        updateUserProfile({
          profileCurrency: currencyCode,
        });
      }
    },
    [updateUserProfile, user]
  );
  const saveDistanceSystem = useCallback(
    (distanceSystem: DistanceSystem) => {
      setDistanceSystem(distanceSystem);
      setDistanceSystemCookie(distanceSystem);
      if (user) {
        updateUserProfile({
          isKilometers: distanceSystem === "metric",
        });
      }
    },
    [updateUserProfile, user]
  );
  const saveLanguage = useCallback(
    (language: SupportedLanguageCode) => {
      setLanguage(language);
      setLanguageCookie(language);
      if (user) {
        updateUserProfile({
          profileLanguage: language,
        });
      }
    },
    [updateUserProfile, user]
  );

  // Set user and sync preferences when the user is loaded from the session
  const setUserAndSyncPreferences = useCallback(
    (user: UserProfile) => {
      queryClient.setQueryData(["user-query-key"], user);
      syncUserPrefrencesFromSession(user);
    },
    [syncUserPrefrencesFromSession, queryClient]
  );

  const value = useMemo(
    () => ({
      prefers12Hour,
      currencyCode,
      distanceSystem,
      language,
      savePrefers12Hour,
      saveCurrencyCode,
      saveDistanceSystem,
      saveLanguage,
      user,
      // We dont want to use the setter directly to clear it, because we want to make sure that the user is also cleared from local storage
      setUserAndSyncPreferences,
      resetUser,
      loadingSession,
      setLoadingSession,
      updateUserProfile,
    }),
    [
      prefers12Hour,
      currencyCode,
      distanceSystem,
      language,
      savePrefers12Hour,
      saveCurrencyCode,
      saveDistanceSystem,
      saveLanguage,
      user,
      setUserAndSyncPreferences,
      resetUser,
      loadingSession,
      updateUserProfile,
    ]
  );

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
}

export default UserProvider;
